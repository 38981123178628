<template>
  <div>
    <b-container>
      <b-row>
        <b-col sm="6" class="pl-0">
          <div class="pb-2">
            <label class="label-bold">Username</label>
            <span class="disabled-value pl-1">{{ copyUser.email }}</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" class="pl-0">
          <b-form-group label="First name" label-for="firstName">
            <b-form-input
              id="firstName"
              v-model="copyUser.firstName"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Last name" label-for="lastName">
            <b-form-input
              id="lastName"
              v-model="copyUser.lastName"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <individual-contact-edit
      :contact="copyUser"
      ref="contact"
      @addRow="addRow"
      @remove="removeEntry"
      @tagSelect="onTagSelect"
      @addRowAddress="addRowAddress"
    />

    <span
      >The contact details saved on this page will be made available to members
      of any SmartyFile organisation you join for the purposes of pre-filling
      submissions.</span
    >
  </div>
</template>

<script>
import IndividualContactEdit from "../interface/IndividualContactEdit";
import {mapActions} from "vuex";
import handleApiError from "../../shared/apiErrorUtil";
import {
  clearEmptyAddressFields,
  clearEmptyContactFields,
  forceUniqueTags,
  scrollToError,
} from "@/utils/utils";
import {required} from "vuelidate/lib/validators";

export default {
  name: "UserEdit",
  components: {
    IndividualContactEdit,
  },
  props: ["user"],
  data() {
    return {
      /**
       * A partial copy of the user to avoid directly modifying data from the store
       */
      copyUser: {
        id: this.user.id,
        email: this.user.email,
        emails: this._.cloneDeep(this.user.emails),
        phoneNumbers: this._.cloneDeep(this.user.phoneNumbers),
        websites: this._.cloneDeep(this.user.websites),
        addresses: this._.cloneDeep(this.user.addresses),
        firstName: this.user.firstName,
        lastName: this.user.lastName,
      },
    };
  },
  validations: {
    copyUser: {
      firstName: {required},
      lastName: {required},
    },
  },
  methods: {
    ...mapActions("userStore", ["patchUser"]),
    addRow(arrayName, name) {
      const row = {
        [name]: "",
        tags: [],
      };
      if (this.copyUser[arrayName].length === 0) {
        row.tags = ["Primary"];
      }
      this.copyUser[arrayName].push(row);
    },
    addRowAddress() {
      const row = {
        addressLine1: "",
        addressLine2: "",
        country: "",
        postcode: "",
        state: "",
        suburbLocality: "",
        tags: [],
      };
      if (this.copyUser.addresses.length === 0) {
        row.tags = ["Primary"];
      }
      this.copyUser.addresses.push(row);
    },
    checkErrors() {
      this.$v.$touch();
      return this.$refs.contact.invalidFields();
    },
    clearEmptyData() {
      clearEmptyContactFields(this.copyUser.emails, "email");
      clearEmptyContactFields(this.copyUser.phoneNumbers, "number");
      clearEmptyContactFields(this.copyUser.websites, "url");
      clearEmptyAddressFields(this.copyUser.addresses);
    },
    onTagSelect(name, contactArray, selectedEntry) {
      this.copyUser[name] = forceUniqueTags(contactArray, selectedEntry);
    },
    removeEntry(name, obj) {
      this.copyUser[name] = this.copyUser[name].filter(
        element => element !== obj
      );
    },
    save() {
      // First clear fully empty rows
      this.clearEmptyData();
      const checkedErrors = this.checkErrors();
      if (checkedErrors.length > 0) {
        scrollToError(checkedErrors[0]);
        return Promise.reject();
      }
      this.copyUser.emails.forEach(x => (x.email = x.email.toLowerCase()));
      return this.patchUser(this.copyUser)
        .then(() => "Contacts Updated")
        .catch(err => {
          // TODO when API fails validation this should be caught and shown as validation errors
          // Check the type of error, if it's a validation error display here.
          // Rethrow the error to the parent component to indicate the operation failed
          handleApiError(err, this);
          throw err;
        });
    },
  },
};
</script>

<style>
.disabled-value {
  color: #495057;
}
</style>
