<template>
  <div>
    <personal-detail-view :contact-obj="contactObj"></personal-detail-view>
    <contact-detail-view :contactObj="contactObj"></contact-detail-view>
  </div>
</template>

<script>
import PersonalDetailView from "../interface/PersonalDetailView";
import ContactDetailView from "../interface/ContactDetailView";
import {dataMapper} from "@/utils/dataMapper";

export default {
  name: "UserView",
  components: {
    "personal-detail-view": PersonalDetailView,
    "contact-detail-view": ContactDetailView,
  },
  props: ["user"],
  computed: {
    contactObj() {
      return dataMapper.contactDetails(this.user);
    },
  },
};
</script>
