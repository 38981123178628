<template>
  <div>
    <user-details />
  </div>
</template>

<script>
import UserDetails from "../components/user/UserDetails";

export default {
  name: "home",
  components: {UserDetails},
};
</script>
